import { createCookie } from '@remix-run/node';
import { parse, serialize } from 'cookie';

import { uaDetectServer } from './helper/uaDetect.server';

import type { CookieOptions } from '@remix-run/node';
import type { CookieSerializeOptions } from 'cookie';

const createCookieWrapper = (cookieName: string, cookie: CookieOptions, request: Request) => {
  const browserIsSameSiteNoneCookieCompatible = uaDetectServer(request).browserIsSameSiteNoneCookieCompatible;
  if (browserIsSameSiteNoneCookieCompatible) cookie.sameSite = 'none';

  return createCookie(cookieName, cookie);
};

export const featureFlagsCookie = (request: Request) => {
  const cookie: CookieOptions = {
    // probs need to change before going live
    path: '/',
    httpOnly: true,
    secure: true,
    maxAge: 3600 * 24,
  };

  return createCookieWrapper('FEATURE_FLAGS', cookie, request);
};

export const inactiveFeatureFlagsCookie = (request: Request) => {
  const cookie: CookieOptions = {
    // probs need to change before going live
    path: '/',
    httpOnly: true,
    secure: true,
    maxAge: 3600 * 24,
  };

  return createCookieWrapper('INACTIVE_FEATURE_FLAGS', cookie, request);
};

export const getCookie = (name: 'ACCESS_TOKEN' | 'REFRESH_TOKEN', documentCookieStr: string | null) => {
  if (!documentCookieStr) return null;
  const cookies = parse(documentCookieStr);
  const isValidCookie = name in cookies && cookies[name].length > 0 && cookies[name].toLowerCase() !== 'undefined';
  return isValidCookie ? cookies[name] : null;
};

export const setAuthCookie = (
  name: 'ACCESS_TOKEN' | 'REFRESH_TOKEN',
  value: string,
  request: Request,
  cookieOptions?: CookieSerializeOptions,
) => {
  const browserIsSameSiteNoneCookieCompatible = uaDetectServer(request).browserIsSameSiteNoneCookieCompatible;
  const options = {
    path: '/',
    secure: true,
    sameSite: browserIsSameSiteNoneCookieCompatible,
    maxAge: name === 'ACCESS_TOKEN' ? 3600 * 24 : 3600 * 24 * 90,
  };
  return serialize(name, value, { ...options, ...cookieOptions });
};

export const deleteCookie = (name: 'ACCESS_TOKEN' | 'REFRESH_TOKEN', request: Request) =>
  setAuthCookie(name, '', request, { maxAge: 0 });

export const clearInvalidCookies = (documentCookieStr: string | null, request: Request) => {
  if (!documentCookieStr) return;
  const cookies = parse(documentCookieStr);

  Object.keys(cookies).forEach(key => {
    const isCookieAuthToken = key === 'ACCESS_TOKEN' || key === 'REFRESH_TOKEN';
    if (isCookieAuthToken) {
      const cookieVal = cookies[key];
      const isCookieInvalid = !cookieVal || (cookieVal && cookieVal.toLowerCase() === 'undefined');
      if (isCookieInvalid) deleteCookie(key, request);
    }
  });
};
